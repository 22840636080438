import React, {useEffect, useState} from "react";
import {IResourceComponentsProps, useCreate, useNavigation} from "@pankod/refine-core";
import {
    Autocomplete,
    Box,
    Create,
    Drawer,
    Edit,
    FormControl,
    FormLabel,
    OutlinedInput, SaveButton,
    Stack,
    TextField
} from "@pankod/refine-mui";
import axios from "axios";
import { useLocation } from "@pankod/refine-react-router-v6";


export const CategorieEdit: React.FC<IResourceComponentsProps> = () => {
    const [inputs, setInputs] = useState<any>({})
    const { goBack } = useNavigation();
    const location = useLocation();

    console.log(location)



    const handleChangeValue = (value: any, type: string) => {
        setInputs({...inputs, [type]: value})
    }

    useEffect(() => {
        const locationSplit = location?.pathname?.split('/')
        const id = locationSplit?.[locationSplit?.length - 1]

        axios("https://api.cigercisabri.com/api/categories/" + id).then(function (response) {
        console.log(response);
        response?.data?.data?.map((item: any) => {
            setInputs({title: item?.title, order: item?.order})
        })
    })
        .catch(function (error) {
            console.log(error);
        });

    }, [])

    const onSubmit = async (e: any) => {
        e.preventDefault();
        const locationSplit = location?.pathname?.split('/')
        const id = locationSplit?.[locationSplit?.length - 1]

        await axios.patch(`https://api.cigercisabri.com/api/categories/${id}`,
            {
                title: inputs?.title,
                order: inputs?.order
            }
        ).then(function (response) {
            console.log(response);
            goBack();
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    return (
        <>

                <Box
                >
                    <Stack>
                        <Box
                            paddingX="50px"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                paddingX: {
                                    xs: 1,
                                    md: 6,
                                },
                            }}
                        >
                            <form className="form" onSubmit={onSubmit}>
                                <Stack gap="10px" marginTop="10px">

                                    <FormControl>
                                        <FormLabel>
                                            Kategori
                                        </FormLabel>
                                        <OutlinedInput
                                            id="title"
                                            value={inputs?.title}
                                            onChange={(e) => handleChangeValue(e.target.value, "title")}

                                            style={{height: "40px"}}
                                        />

                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>
                                            Sıra
                                        </FormLabel>
                                        <OutlinedInput
                                            id="order"
                                            value={inputs?.order}

                                            onChange={(e) => handleChangeValue(e.target.value, "order")}

                                            style={{height: "40px"}}
                                        />

                                    </FormControl>
                                    <SaveButton type="submit"/>
                                </Stack>
                            </form>
                        </Box>
                    </Stack>
                </Box>
        </>
    )
}
