import React, {useState} from "react";
import {IResourceComponentsProps, useCreate, useNavigation} from "@pankod/refine-core";
import {
    Autocomplete,
    Box,
    Button,
    Create,
    Drawer,
    FormControl,
    FormLabel,
    OutlinedInput, SaveButton,
    Stack,
    TextField,
    Typography
} from "@pankod/refine-mui";
import {useForm, useModalForm} from "@pankod/refine-react-hook-form";
import axios from "axios";


export const GalleryCreate: React.FC<IResourceComponentsProps> = () => {
    const [inputs, setInputs] = useState<any>({})

    const {
        refineCore: { onFinish, formLoading, queryResult },
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm();
    const { goBack } = useNavigation();

    const onSubmit = async (data: any) => {
        const formData = new FormData();
        console.log(data)
        formData.append("image", data?.image?.[0]);
        formData.append("title", data?.title);

        await axios.post("https://api.cigercisabri.com/api/gallery",
            formData
        ).then(function (response) {
            console.log(response);
            goBack();
        })
            .catch(function (error) {
                console.log(error);
            });
    };
    return (
        <>

                <Box
                    component="form"
                    sx={{display: "flex", flexDirection: "column"}}
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack>
                        <Box
                            paddingX="50px"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                paddingX: {
                                    xs: 1,
                                    md: 6,
                                },
                            }}
                        >
                             <FormControl>
                            <FormLabel required>
                                Resim
                            </FormLabel>
                            <Stack
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border="1px dashed  "
                                borderColor="primary.main"
                                borderRadius="5px"
                                padding="50px"
                                marginTop="5px"
                            >
                                <label htmlFor="image">
                                    <input
                                        id="file"
                                        {...register("image")}
                                        type="file"
                                        name="image"
                                    />
                                </label>

                            </Stack>

                        </FormControl>
                        <TextField
                            {...register("title", {
                                required: "This field is required",
                            })}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            type="text"
                            label="Başlık"
                            name="title"
                        />
                        </Box>
                        <Box display="flex" justifyContent="end" >

                         <Button type="submit" color="success" variant="contained" >Oluştur</Button>
                        </Box>
                    </Stack>
                </Box>
        </>
    )
}
