import { useRouterContext } from "@pankod/refine-core";
import {Box, Typography} from "@pankod/refine-mui";
import React from "react";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    const { Link } = useRouterContext();

    return (
        <Link to="/" style={{textDecoration: 'none'}}>
            <Box
                sx={{
                    height: "72px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {collapsed ? (
                    <Box component="img" src={"/favicon.png"} sx={{ color: "common.white", width: 30, height: 30, borderRadius: 30 }} />
                ) : (
                    <Typography sx={{color: '#fff', textDecoration: 'none', fontWeight: 700, }}>Ciğerci Sabri</Typography>
                )}
            </Box>
        </Link>
    );
};
