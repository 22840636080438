import React from "react";
import {Box, Button, DataGrid, DateField, Divider, Grid, GridColumns, List, Tooltip, Typography, useDataGrid} from "@pankod/refine-mui";
import {useList} from "@pankod/refine-core";

export const DashboardPage: React.FC = () => {
    const {data} = useList({resource: "subscriptions"})
    // @ts-ignore
    const emails = data?.data?.data;
    const {dataGridProps, tableQueryResult} = useDataGrid<any>({resource: 'messages'});
    const messageData = tableQueryResult?.data?.data
    // @ts-ignore
    const messages = messageData?.data ?? []


    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "name",
                flex: 2,
                headerName: "İsim",
                width: 100,
                renderCell: (params: any) => {
                    console.log(params)
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "phone_number",
                flex: 2,
                headerName: "Telefon Numarası",
                width: 150,
                renderCell: (params: any) => {
                    console.log(params)
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "email",
                flex: 2,
                headerName: "E-mail",
                width: 150,
                renderCell: (params: any) => {
                    console.log(params)
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "subject",
                flex: 2,
                headerName: "Konu",
                width: 500,
                renderCell: (params: any) => {
                    console.log(params)
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
            {
                field: "message",
                flex: 2,
                headerName: "Mesaj",
                width: 500,
                renderCell: (params: any) => {
                    console.log(params)
                    return (
                      <Tooltip title={params?.value}>
                        <span> {params?.value} </span>
                      </Tooltip>
                    );
                  }
            },
        ],
        [],
    );
    return (
        <>
            <h1>Yönetim Paneline Hoşgeldiniz.</h1>
            <Grid container width="100%" rowSpacing={2} columnSpacing={2} xs={12}>
                <Grid item xs={12}>
                    <List title={<Typography variant="h4">Gelen Mesajlar</Typography>}>
                        <DataGrid {...dataGridProps} rows={messages ?? []} getRowId={(row) => row?._id} columns={columns} autoHeight rowCount={5} />
                    </List>
                </Grid> 
               
            </Grid>
        </>
    );
};