import React, {useState} from "react";
import {IResourceComponentsProps, useCreate} from "@pankod/refine-core";
import {
    Autocomplete,
    Box,
    Create,
    Drawer,
    FormControl,
    FormLabel,
    OutlinedInput, SaveButton,
    Stack,
    TextField
} from "@pankod/refine-mui";
import {useForm, useModalForm} from "@pankod/refine-react-hook-form";


export const CategorieCreate: React.FC<IResourceComponentsProps> = () => {
    const [inputs, setInputs] = useState<any>({})

    const {
        refineCore: { onFinish, formLoading, queryResult },
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm();

    const handleChangeValue = (value: any, type: string) => {
        setInputs({...inputs, [type]: value})
    }

    const {mutate} = useCreate();

    function useCreateMutate() {
        mutate({
                resource: "categories",
                values: {
                    title: inputs?.title,
                    order: inputs?.order,
                },
            },
            {
                onError: (error, variables, context) => {
                    alert(error.message)

                },
                onSuccess: async (data, variables, context) => {
                    setInputs({});
                }
            });
    }
    return (
        <>

                <Create
                    resource="categories"
                    footerButtonProps={{sx: {display: 'none'}}}
                >
                    <Stack>
                        <Box
                            paddingX="50px"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                paddingX: {
                                    xs: 1,
                                    md: 6,
                                },
                            }}
                        >
                            <form className="form" onSubmit={handleSubmit(onFinish)}>
                                <Stack gap="10px" marginTop="10px">

                                    <FormControl>
                                        <FormLabel>
                                            Kategori
                                        </FormLabel>
                                        <OutlinedInput
                                            id="title"
                                            onChange={(e) => handleChangeValue(e.target.value, "title")}

                                            style={{height: "40px"}}
                                        />

                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>
                                            Sıra
                                        </FormLabel>
                                        <OutlinedInput
                                            id="order"
                                            onChange={(e) => handleChangeValue(e.target.value, "order")}

                                            style={{height: "40px"}}
                                        />

                                    </FormControl>
                                    <SaveButton onClick={() => {
                                        useCreateMutate();

                                    }}/>
                                </Stack>
                            </form>
                        </Box>
                    </Stack>
                </Create>
        </>
    )
}
