import React, { useEffect } from "react";
import {IResourceComponentsProps, useDelete} from "@pankod/refine-core";
import {Button, CreateButton, DataGrid,  DeleteButton,  Grid,  GridActionsCellItem, GridColumns, IconButton, List, Paper, Popover, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close, MoreVertSharp } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";

export const GalleryList: React.FC<IResourceComponentsProps> = () => {
    const { mutate: mutateDelete } = useDelete();
    const [gallery, setGallery] = React.useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    useEffect(() => {
        fetch('https://api.cigercisabri.com/api/gallery').then((res) => res.json()).then((data) => {
            setGallery(data?.data)
        });

    }, [])

    return (
        <>
            <Grid container columnSpacing={1}>
                <Grid item xs={12} md={12}>
                    <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            padding={1}
                            direction="row"
                            gap={2}
                        >
                            <Typography variant="h5">
                                Fotoğraflar
                            </Typography>
                            <CreateButton
                                variant="outlined"
                                sx={{ marginBottom: "5px" }}
                            >
                                Fotoğraf Ekle
                            </CreateButton>
                    </Stack>
                </Grid>
                {
                    gallery?.map((item: any) => 
                        <Grid item xs={12} md={5.9} lg={2.9}>
                            <Stack spacing={2} sx={{border: '1px solid #333333', p:1}}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" component="div">
                                    <Typography align="center">{item.title}</Typography>
                                    <IconButton
                                        aria-describedby={popoverId}
                                        onClick={handleClick}
                                        sx={{ marginRight: "10px", marginTop: "4px" }}
                                        aria-label="settings"
                                    >
                                        <MoreVertSharp />
                                    </IconButton>
                                    <Popover
                                        id={popoverId}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        <DeleteButton size="small" recordItemId={item?._id} sx={{padding: "5px 10px", width: '100%'}} >
                                            Sil
                                        </DeleteButton>
                                    </Popover>
                                </Box>
                                <Box component="img" src={'https://api.cigercisabri.com/uploads/' + item?.image?.filename + '_resized.webp'} height={200} width="auto" />
                            </Stack>
                        </Grid>
                    )
                }
            </Grid>

        </>
    )
}
