import React, { useEffect } from "react";
import {HttpError, useShow} from "@pankod/refine-core";
import {Controller, useForm, UseModalFormReturnType,} from "@pankod/refine-react-hook-form";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Drawer,
    Edit,
    FormControl,
    FormLabel,
    Stack,
    TextField,
    Typography
} from "@pankod/refine-mui";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {IProduct} from "../../interfaces";
import axios from "axios";

export const EditProduct = React.memo(({editDrawerFormProps, id}: {
    editDrawerFormProps:
        UseModalFormReturnType<IProduct, HttpError, IProduct, {}>; id: any
}) => {
    const {
        modal: {visible, close},
    } = editDrawerFormProps;
    const {
        watch,
        saveButtonProps,
        refineCore: {formLoading},
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm();
    const imageInput = watch("images");
    const {queryResult} = useShow({
        id,
    });
    const product = queryResult?.data?.data;
    const [categoryValue, setCategoryValue] = React.useState<string>('');

    const [categoryOptions, setCategoryOptions] = React.useState<any>([]);

    useEffect(() => {
        axios("https://api.cigercisabri.com/api/categories").then(function (response) {
        console.log(response);
        response?.data?.data?.map((item: any) => {
            setCategoryOptions((prevCategoryOptions: any) => [...prevCategoryOptions, {'_id': item.category._id, 'title': item.category.title}]);
            console.log(categoryOptions, item)
        })
    })
        .catch(function (error) {
            console.log(error);
        });

    }, [])

    const onSubmit = async (data: any) => {
        const formData = new FormData();
        formData.append("image", data?.image?.[0]);
        formData.append("title", data?.title);
        formData.append("description", data?.description);
        formData.append("price", data?.price);
        if (categoryValue) {
            formData.append("categoryId", categoryValue);
        }
        await axios.patch(`https://api.cigercisabri.com/api/products/${id}`,
            formData
        ).then(function (response) {
            console.log(response);
            close();
        })
            .catch(function (error) {
                console.log(error);
            });
    };


    return (
        <Drawer
            sx={{zIndex: "1301"}}
            PaperProps={{
                sx: {
                    width: {sm: "100%", md: 500},
                    height: '100%',
                    overflow: 'scroll',
                    '& .MuiPaper-root': {overflow: 'scroll'}
                }
            }}
            open={visible}
            onClose={close}
            anchor="right"
        >

            <Edit
                resource="products"
                footerButtonProps={{sx: {display: 'none'}}}
            >
                <Stack>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: {
                                xs: 1,
                                md: 6,
                            },
                        }}
                    >
                        <Box
                            component="form"
                            sx={{display: "flex", flexDirection: "column"}}
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >


                            <FormControl>
                                <FormLabel>
                                    Resim
                                </FormLabel>
                                <Stack
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    border="1px dashed  "
                                    borderColor="primary.main"
                                    borderRadius="5px"
                                    padding="50px"
                                    marginTop="5px"
                                >
                                    <label htmlFor="image">
                                        <input
                                            id="file"
                                            {...register("image")}
                                            type="file"
                                            name="image"
                                        />
                                        <Avatar
                                            sx={{
                                                cursor: "pointer",
                                                width: {
                                                    xs: 100,
                                                    md: 140,
                                                },
                                                height: {
                                                    xs: 100,
                                                    md: 140,
                                                },
                                            }}
                                            src={imageInput && imageInput.length > 0 && imageInput[0].url}

                                            alt="Store Location"
                                        />
                                    </label>

                                </Stack>

                            </FormControl>

                            <TextField
                                {...register("title")}
                                defaultValue={product?.title}
                                margin="normal"
                                fullWidth

                                InputLabelProps={{shrink: true}}
                                type="text"
                                label="Başlık"
                                name="title"
                            />

                            <TextField
                                {...register("description")}
                                defaultValue={product?.description}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                multiline
                                minRows={5}
                                label="Açıklama"
                                name="description"
                            />
                         
                            <TextField
                                {...register("price")}
                                defaultValue={product?.price}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                type="number"
                                label="Fiyat"
                                name="price"
                            />
                          
                                <Controller
                                    control={control}
                                    name="category"
                                    defaultValue={product?.category}
                                    render={({field}) => (
                                        <Autocomplete
                                        options={categoryOptions}
                                        getOptionLabel={(item: any) => item.title}
                                        isOptionEqualToValue={(option, value) =>
                                            {
                                                console.log(option, value)
                                                return value === undefined || option?._id?.toString() === (value?._id ?? value)?.toString()}
                                        }
                                        onChange={(e, newValue: any) => {
                                            console.log(newValue)
                                            setCategoryValue(newValue?._id);
                                        }}
                                        disablePortal
                                        id="category"
                                        sx={{height: "60px"}}
                                            renderInput={(params) => <TextField {...params} {...register("category")}
                                                                                label="Kategori"/>}
                                        />)}/>
                            <Box display="flex" justifyContent="end">

                                <Button type="submit" color="success" variant="contained">Oluştur</Button>
                            </Box>
                        </Box>
                    </Box>
                </Stack>
            </Edit>
        </Drawer>
    );
});
