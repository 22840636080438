import React, { useState } from "react";
import {BaseKey, HttpError, useModal} from "@pankod/refine-core";
import {
    Avatar,
    Card,
    CardHeader,
    Box,
    IconButton,
    CardContent,
    Typography,
    Tooltip,
    Popover,
    Button,
    Divider,
    TextField, DeleteButton, Drawer,
} from "@pankod/refine-mui";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { IProduct } from "interfaces";
import {EditProduct} from "./edit";
import {useModalForm} from "@pankod/refine-react-hook-form";

type PropductItem = {
    product: any;
};

export const ProductItem: React.FC<PropductItem> = React.memo(({
    product,
}) => {
    const { _id, categoryName, title, description, price, image } = product;
    const editDrawerFormProps = useModalForm<IProduct, HttpError, IProduct>({
        refineCoreProps: { action: "edit" },
    });

    const {
        modal: { show: showEditDrawer },
    } = editDrawerFormProps;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    return (
        <>
        
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                {
                    image?.filename ? <Box component="img" src={`https://api.cigercisabri.com/uploads/${image.filename}_resized.webp`} width={70} height="auto" />
                    : <Avatar sx={{width: 70, height: 'auto'}} />
                }
                <Typography maxWidth={300} minWidth={100} align="center">{categoryName}</Typography>
                <Typography maxWidth={300} minWidth={150} align="center">{title}</Typography>
                <Typography maxWidth={300} minWidth={200} align="center">{description}</Typography>
                <Typography maxWidth={300} minWidth={100} align="center">{price}</Typography>
                <Box component="div" maxWidth={100}>
                        <IconButton
                            aria-describedby={popoverId}
                            onClick={handleClick}
                            sx={{ marginRight: "10px", marginTop: "4px" }}
                            aria-label="settings"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            id={popoverId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    showEditDrawer();
                                    setAnchorEl(null);
                                }}
                                size="small"
                                startIcon={<EditIcon />}
                                sx={{
                                    padding: "5px 10px",
                                }}
                            >
                                Düzenle
                            </Button>
                            <DeleteButton size="small" recordItemId={_id} sx={{padding: "5px 10px", width: '100%'}} >
                                Sil
                            </DeleteButton>
                        </Popover>
                    </Box>
            </Box>
            <EditProduct editDrawerFormProps={editDrawerFormProps} id={_id} />
        </>
    );
})

/*
 <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
                border: '1px solid #bfbfbf',
            }}
        >
            <CardHeader
                action={
                    <Box component="div">
                        <IconButton
                            aria-describedby={popoverId}
                            onClick={handleClick}
                            sx={{ marginRight: "10px", marginTop: "4px" }}
                            aria-label="settings"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            id={popoverId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    showEditDrawer();
                                    setAnchorEl(null);
                                }}
                                size="small"
                                startIcon={<EditIcon />}
                                sx={{
                                    padding: "5px 10px",
                                }}
                            >
                                Düzenle
                            </Button>
                            <DeleteButton size="small" recordItemId={_id} sx={{padding: "5px 10px", width: '100%'}} >
                                Sil
                            </DeleteButton>
                        </Popover>
                    </Box>
                }
                sx={{ padding: 0 }}
            />
            <CardContent
                sx={{
                    paddingX: "30px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Divider />
                <Box display="flex" justifyContent="center" alignItems="center" sx={{p:1,   width:"100%", height:"auto"}}>
                    <Box component="img" src={`http://localhost:3333/uploads/${image.filename}_resized.webp`} width={"auto"} height={200}  />
                </Box>
                    <Tooltip title={title}>
                    <Typography
                        sx={{
                            fontWeight: 800,
                            fontSize: "18px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textAlign: 'center'
                        }}
                    >
                        {title}
                    </Typography>
                </Tooltip>
                <Tooltip title={description}>
                    <Typography
                        variant="body2"
                        sx={{
                            mt: 2,
                            overflowWrap: "break-word",
                            color: "text.secondary",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                            textTransform: 'capitalize'
                        }}
                    >
                        {description}
                    </Typography>
                </Tooltip>
                <Tooltip title={`${price}₺`} placement="top">
                    <Typography
                        align="right"
                        sx={{
                            fontWeight: 500,
                            fontSize: "24px",
                            overflowWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                        }}
                    >{`${price}₺`}</Typography>
                </Tooltip>

            </CardContent>
        </Card>
        */