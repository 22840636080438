import React, {useEffect, useState} from "react";
import {
    IResourceComponentsProps,
} from "@pankod/refine-core";
import { useModalForm } from "@pankod/refine-react-hook-form";
import {
    Grid,
    Paper,
    Typography,
    InputBase,
    IconButton,
    Stack,
    CreateButton,
} from "@pankod/refine-mui";
import SearchIcon from "@mui/icons-material/Search";

import {
    ProductItem
} from "components";
import { IProduct } from "interfaces";
import { Box } from "@mui/system";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
    const [isChanged, setIsChanged] = useState<Boolean>(false);
    const [searchVal, setSearchVal] = useState<string>('');
    const [products, setProducts] = useState<any>(null);

    useEffect(() => {
        fetch('https://api.cigercisabri.com/api/products').then((res) => res.json()).then((data) => {
            setProducts(data?.data)
        });

    }, [])

    const createDrawerFormProps = useModalForm<any>({
        refineCoreProps: { action: "create" },
    });

    const handleChangeSearch = (val: string) => {
        setSearchVal(val)
    }

    const filteredProducts = products?.filter((product: any) => {
        return product?.title.toLowerCase().includes(searchVal.toLowerCase());
      });

    useEffect(() => {
        setIsChanged(false);
    }, [isChanged])
    return (
        <>
            <Paper
                sx={{
                    paddingX: { xs: 3, md: 2 },
                    paddingY: { xs: 2, md: 3 },
                    my: 0.5,
                }}
            >
                <Grid container columns={16}>
                    <Grid item xs={16} md={16}>
                        <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="wrap"
                            padding={1}
                            direction="row"
                            gap={2}
                        >
                            <Typography variant="h5">
                                Ürünler
                            </Typography>
                            <Paper
                                component="form"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: 400,
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Paket Ara"
                                    onChange={(
                                        e: any
                                    ) => {
                                        handleChangeSearch(e.target.value);
                                    }}
                                />
                                <IconButton
                                    sx={{ p: "10px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                            <CreateButton
                                variant="outlined"
                                sx={{ marginBottom: "5px" }}
                            >
                                Ürün Ekle
                            </CreateButton>
                        </Stack>
                        <Grid container>
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{p: 1,borderBottom: '1px solid #000'}}>
                                <Typography width={70} align="center">Resim</Typography>
                                <Typography maxWidth={100} minWidth={100} align="center">Kategori</Typography>
                                <Typography maxWidth={300} minWidth={150} align="center">İsim</Typography>
                                <Typography maxWidth={300} minWidth={200} align="center">Açıklama</Typography>
                                <Typography maxWidth={300} minWidth={100} align="center">Fiyat</Typography>
                                <Typography maxWidth={100} align="center">Düzenle/Sil</Typography>
                            </Box>
                            
                            {filteredProducts?.length > 0 ? (
                                filteredProducts?.map((product: IProduct) => (
                                    <Grid
                                        item
                                        xs={12}
                                        key={product.id}
                                        sx={{ padding: "8px" }}
                                    >
                                        <ProductItem
                                            product={product}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Grid
                                    container
                                    justifyContent="center"
                                    padding={3}
                                >
                                    <Typography variant="body2">
                                        Ürün yok.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
