import {
    Autocomplete,
    Avatar,
    Box, Button,
    Checkbox,
    FormControl, FormHelperText,
    FormLabel,
    Grid, IconButton,
    Input,
    Stack,
    TextField, Typography
} from "@mui/material";
import {Controller} from "react-hook-form";
import React, { useEffect } from "react";
import axios from "axios";
import { useNavigation } from "@pankod/refine-core";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "@pankod/refine-react-hook-form";
import { useAutocomplete } from "@pankod/refine-mui";

interface ICategory {
    _id: string;
    title: string;
}

export function ProductCreate() {
    const {
        watch,
        saveButtonProps,
        refineCore: {formLoading},
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm();
    const imageInput = watch("images");
    const { goBack } = useNavigation();
    const [categoryValue, setCategoryValue] = React.useState<string>('');
    const [categoryOptions, setCategoryOptions] = React.useState<any>([]);

    useEffect(() => {
        axios("https://api.cigercisabri.com/api/categories").then(function (response) {
        console.log(response);
        response?.data?.data?.map((item: any) => {
            setCategoryOptions((prevCategoryOptions: any) => [...prevCategoryOptions, {'_id': item.category._id, 'title': item.category.title}]);
            console.log(categoryOptions, item)
        })
    })
        .catch(function (error) {
            console.log(error);
        });

    }, [])

    const onSubmit = async (data: any) => {
        const formData = new FormData();
        console.log(data)
        formData.append("image", data?.image?.[0]);
        formData.append("title", data?.title);
        formData.append("description", data?.description);
        formData.append("price", data?.price);
        formData.append("categoryId", categoryValue);

        await axios.post("https://api.cigercisabri.com/api/products",
            formData
        ).then(function (response) {
            console.log(response);
            goBack();
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <Box>
            <Box>
                <IconButton onClick={() => goBack()}><ArrowBackIcon/></IconButton>
            </Box>
            <Box
                component="form"
                sx={{display: "flex", flexDirection: "column"}}
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
            >

                <Grid item container spacing={2}>
                    <Grid item container xs={12}>

                        <FormControl>
                            <FormLabel required>
                                Resim
                            </FormLabel>
                            <Stack
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border="1px dashed  "
                                borderColor="primary.main"
                                borderRadius="5px"
                                padding="50px"
                                marginTop="5px"
                            >
                                <label htmlFor="image">
                                    <input
                                        id="file"
                                        {...register("image")}
                                        type="file"
                                        name="image"
                                    />
                                    <Avatar
                                        sx={{
                                            cursor: "pointer",
                                            width: {
                                                xs: 100,
                                                md: 140,
                                            },
                                            height: {
                                                xs: 100,
                                                md: 140,
                                            },
                                        }}
                                        src={imageInput && imageInput.length > 0 && imageInput[0].url}

                                        alt="Store Location"
                                    />
                                </label>

                            </Stack>

                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...register("title", {
                                required: "This field is required",
                            })}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            type="text"
                            label="Başlık"
                            name="title"
                        />

                        <TextField
                            {...register("description")}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            multiline
                            minRows={5}
                            label="Açıklama"
                            name="description"
                        />
                   
                       
                    </Grid>
                    <Grid item xs={12} md={6} mt={2}>
                        <Stack spacing={1.5}>
                        <TextField
                            {...register("price", {
                                required: "This field is required",
                                valueAsNumber: true,
                            })}
                            margin="normal"
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            label="Fiyat"
                            name="price"
                        />
                            <Controller
                                control={control}
                                name="category"
                                defaultValue={null as any}
                                render={({field}) => (
                                    <Autocomplete
                                        options={categoryOptions}
                                        getOptionLabel={(item: any) => item.title}
                                        isOptionEqualToValue={(option, value) =>
                                            {
                                                console.log(option, value)
                                                return value === undefined || option?._id?.toString() === (value?._id ?? value)?.toString()}
                                        }
                                        onChange={(e, newValue: any) => {
                                            console.log(newValue)
                                            setCategoryValue(newValue?._id);
                                        }}
                                        disablePortal
                                        id="category"
                                        sx={{height: "60px"}}
                                        renderInput={(params) => <TextField {...params}  {...register("category", {
                                            required: "This field is required",
                                        })} label="Kategori" />}
                                    />)}/>
                        </Stack>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="end" >

                    <Button type="submit" color="success" variant="contained" >Oluştur</Button>
                </Box>
            </Box>
        </Box>
    );
};
