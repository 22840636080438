import React from "react";
import {IResourceComponentsProps, useDelete, useRouterContext} from "@pankod/refine-core";
import {CreateButton, DataGrid,  EditButton,  GridActionsCellItem, GridColumns, List, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

export const CategorieList: React.FC<IResourceComponentsProps> = () => {
    const { Link } = useRouterContext();

    const {dataGridProps, tableQueryResult} = useDataGrid<any>({resource: 'categories'});
    console.log("dataGridProps", dataGridProps, "table", tableQueryResult)
    // @ts-ignore
    const categoriesData = tableQueryResult?.data?.data?.data
    console.log(categoriesData)
    const { mutate: mutateDelete } = useDelete();

    const categoryData = categoriesData?.map((item: any, index: number) => ({
        id: item?.category?._id,
        title: item?.category?.title,
        order: item?.category?.order,
      }));

      console.log(categoryData)

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "order",
                flex: 2,
                headerName: "Sıralama",
                width: 450
            },
            {
                field: "title",
                flex: 2,
                headerName: "Başlık",
                width: 450
            },{
                field: "actions",
                flex: 1,
                headerName: "Seçenekler",
                type: "actions",
                width: 20,
                getActions: function render({ row }) {
                    console.log(row)
                    return [
                        <GridActionsCellItem
                            key={2}
                            label="Sil"
                            icon={<Close color="error" />}
                            onClick={() => {
                                mutateDelete({
                                    resource: "categories",
                                    id: row?.id,
                                });
                            }}
                            showInMenu
                        />,
                        <Link to={"/categories/edit/" + row?.id} style={{color: '#fff'}}
                        >Düzenle </Link>,
                    ];
                },
            },
        ],
        [],
    );

    return (
        <>
            <CreateButton hideText sx={{display:'flex', ml: 'auto', mb: 4}} />
            <div style={{ height: 400, width: "100%" }}>                
                <DataGrid
                    rows={categoryData ?? []}
                    columns={columns}
                />            
            </div>
        </>
    )
}
