import { Refine } from "@pankod/refine-core";
import { KBarProvider } from "@pankod/refine-kbar";
import {
    ErrorComponent,
    ReadyPage,
    Layout,
    GlobalStyles,
    CssBaseline,
    RefineSnackbarProvider, responsiveFontSizes, createTheme, ThemeProvider,
} from "@pankod/refine-mui";
import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { authProvider } from "authProvider";
import { DashboardPage } from "pages/dashboard";
import { AuthPage } from "pages/auth";
import { ProductList } from "pages/products";
import { ColorModeContextProvider } from "contexts";
import { Header, Title } from "components";
import StyleIcon from '@mui/icons-material/Style';
import React from "react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {FaqList} from "./pages/faq";
import {FaqCreate} from "./pages/faq/create";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { MessageList } from "pages/messages";
import { ProductCreate } from "pages/products/create";
import { CategorieList } from "pages/categories";
import { CategorieCreate } from "pages/categories/create";
import { GalleryList } from "pages/gallery";
import { GalleryCreate } from "pages/gallery/create";
import CollectionsIcon from '@mui/icons-material/Collections';
import ClassIcon from '@mui/icons-material/Class';
import { CategorieEdit } from "pages/categories/edit";

const App: React.FC = () => {
    const { t, i18n } = useTranslation();
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    // @ts-ignore
    let customTheme = createTheme({
        palette: {
            primary: {
                main: "#330f49",
            },
            secondary: {
                main: "#45d0c8",
            },
        },
    });

    customTheme = responsiveFontSizes(customTheme);

    console.log(process.env.PUBLIC_URL)

    return (
        <ThemeProvider theme={customTheme}>
        <KBarProvider>
            <ColorModeContextProvider>
                <CssBaseline />
                <GlobalStyles
                    styles={{ html: { WebkitFontSmoothing: "auto" } }}
                />
                <RefineSnackbarProvider>
                    <Refine
                        routerProvider={{
                            ...routerProvider,

                        }}
                        dataProvider={dataProvider(
                            'https://api.cigercisabri.com/api',
                        )}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}
                        DashboardPage={DashboardPage}
                        Title={Title}
                        Layout={Layout}
                        Header={Header}
                        LoginPage={() => (
                            <AuthPage
                                type="login"
                                formProps={{
                                    defaultValues: {
                                        email: "admin@gmail.com",
                                        password: "editor",
                                    },
                                }}
                            />
                        )}
                        catchAll={<ErrorComponent />}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                        resources={[
                            {
                                name: "products",
                                list: ProductList,
                                create: ProductCreate,
                                icon: <StyleIcon />,
                                options: {
                                    label: "Ürünler"
                                }
                            },
                            {
                                name: "categories",
                                list: CategorieList,
                                create: CategorieCreate,
                                edit: CategorieEdit,
                                icon: <ClassIcon />,
                                options: {
                                    label: "Kategoriler"
                                }
                            },
                            {
                                name: "gallery",
                                list: GalleryList,
                                create: GalleryCreate,
                                icon: <CollectionsIcon />,
                                options: {
                                    label: "Galeri"
                                }
                            },
                            // {
                            //     name: "faq",
                            //     list: FaqList,
                            //     create: FaqCreate,
                            //     icon: <QuestionMarkIcon />,
                            //     options: {
                            //         label: "S.S.S"
                            //     }
                            // },
                            {
                                name: "messages",
                                list: MessageList,
                                icon: <QuestionAnswerIcon />,
                                options: {
                                    label: "Mesajlar"
                                }
                            },

                        ]}
                    />
                </RefineSnackbarProvider>
            </ColorModeContextProvider>
        </KBarProvider>
        </ThemeProvider>
    );
};

export default App;
