import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";

export const TOKEN_KEY = "token";

export const authProvider: AuthProvider = {
    login: async ({ email, password }) => {
        return axios
            .post('https://api.cigercisabri.com/api/' + "users/login", {
                email,
                password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem("token", JSON.stringify(response.data.token));
                }

                return response.data;
            });
    },

    register: async ({ email, password }) => {
        try {
            await authProvider.login({ email, password });
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    },
    updatePassword: async () => {
        return Promise.resolve();
    },
    forgotPassword: async () => {
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            return Promise.resolve();
        }

        return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }

        return Promise.resolve({
            id: 1,
            name: "Muhammed",
            avatar: "",
        });
    },
};
